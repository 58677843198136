import { createTheme } from '@mui/material/styles'

// Types
import type { Direction } from '@mui/material/styles'

// Fonts
import DanaFont from '@/styles/fonts/dana'
import RobotoFont from '@/styles/fonts/roboto'

const CreateThemeConfig = (dir: Direction) =>
	createTheme({
		direction: dir,
		cssVariables: {
			colorSchemeSelector: 'class'
		},
		colorSchemes: {
			light: {
				palette: {
					mode: 'light',
					primary: { light: '#eff1ff', main: '#c7cffe', dark: '#8191ff', contrastText: '#fff' },
					secondary: { light: '#e6d3ff', main: '#af73ff', dark: '#8327ff', contrastText: '#fff' },
					tertiary: { light: '#ffe7fd', main: '#ffd3fb', dark: '#e5bde1', contrastText: '#000' },
					quaternary: { light: '#f6fded', main: '#dbf8b7', dark: '#c5dfa4', contrastText: '#000' },
					quinary: { light: '#f3f8ff', main: '#dfecff', dark: '#c8d4e5', contrastText: '#000' }
				}
			},
			dark: {
				palette: {
					mode: 'dark',
					primary: { light: '#eff1ff', main: '#c7cffe', dark: '#8191ff', contrastText: '#fff' },
					secondary: { light: '#e6d3ff', main: '#af73ff', dark: '#8327ff', contrastText: '#fff' },
					tertiary: { light: '#ffe7fd', main: '#ffd3fb', dark: '#e5bde1', contrastText: '#000' },
					quaternary: { light: '#f6fded', main: '#dbf8b7', dark: '#c5dfa4', contrastText: '#000' },
					quinary: { light: '#f3f8ff', main: '#dfecff', dark: '#c8d4e5', contrastText: '#000' }
				}
			}
		},
		shape: { borderRadius: 8 },
		typography: {
			fontFamily: `${DanaFont.style.fontFamily}, ${RobotoFont.style.fontFamily}`,
			fontSize: 14
		},
		components: {
			MuiCssBaseline: {
				styleOverrides: {
					body: { scrollBehavior: 'smooth' },
					a: { textDecoration: 'none' }
				}
			}
		},
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 900,
				mdlg: 792,
				lg: 1200,
				xl: 1536
			}
		}
	})

export default CreateThemeConfig
